.form-group{
    display: flex;
    flex-direction: column;
    align-items: end;
    font-family: "Lato";
    label{
        font-size: 1.7rem;
    }
}
.Dropdown-root{
    width: 100%;
   
    .Dropdown-control{
        border-radius: 4px;
        padding: 6px 52px 6px 10px;
    }
}
.textarea-md{
    width: 40rem;
}