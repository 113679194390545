*{
margin: 0;
padding: 0;
}
html{
  font-size: 62.5%;
}
body{
  background: #f1f4f7;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  
  
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
 
@import './css/layout.scss';
@import './css/pages/login.scss';
@import './css/components/nav.scss';
@import './css/components/labels.scss';
@import './css/components/tables/tables.scss';
@import './css/components/buttons/button-circle.scss';
@import './css/components/modals/signup-modal.scss';
@import './css/components/sidebar.scss';
@import './css/components/menu-sidebar.scss';
@import './css/components/modals/modal.scss';
@import './css/components/forms/form.scss';
@import './css/components/tables/tables.scss';
@import './css/components/forms/branch-form.scss';
@import './css/components/forms/advertisement-form.scss';
@import './css/components/forms/autocomplete.scss';
@import './css/components/forms/advertisement-item-form.scss';
@import './css/components/checkbox.scss';
@import './css/components/info-list.scss';
@import './css/filters/filters.scss';
@import './css/components/ul/ul.scss';