.info-list {
   
   
    list-style: none;
    flex: 2;
    padding: 0;

    li {
        
        margin: 0;
        margin-top: 1rem;
      
        font-size: 20px;
        line-height: 1.2;
        background-color: #304c75;
        padding: 9px 8px;
        border-radius: 3px;
        color: #fffefe;

        a {
            text-decoration: none;
        }
    }

}

.info-main {
color: rgb(23, 17, 72);
    p {
        max-width: 60rem;
        font-size: 18px;
        font-family: 'Lato';
    
    }
    h2{
        font-size: 36px;
        font-family: 'Lato';
        font-weight: 600;
    }
}