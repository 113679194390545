.button-circle{
    position: fixed;
    background: #fff;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
    box-shadow: 2px 2px 5px #000;
    bottom: 10px;
}