.branch__form{
    display: grid;
    grid-column-gap: 1rem;
    grid-template-areas: 'name street'
                         'email phone'
                          'city city'
                          'btn btn';
    &-name{
        grid-area: name;
    }
   
    &-street{
        grid-area: street;
    }
   
    &-email{
        grid-area: email;
    }
    
    &-city{
        grid-area: city;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-btn{
        grid-area: btn;
    }
}

