.main-list{
     width: 75%; 
     margin: 10vh auto; 
     font-size: 1.6rem;
      background: #fff;
       list-style: none;
        box-shadow: 0px 0px 8px #888888;
         > li{
            min-height: 5rem;
             font-size: 17px;
              background: linear-gradient(to top, rgb(93, 129, 182) 0%, rgb(21, 70, 147) 100%);

        }
}
.nested-ul{
    li{
        &:first-child{
            &::before{
                background: none;
                margin: 0;
            }
        }
        display: flex;
        align-items: center;
        margin: 10px 0;
    
        line-height: 30px;
        direction: rtl;
        list-style: none;

        &::before{
            display: block;
            flex-shrink: 0;
            width: 12px;
            height: 12px;
            margin-right: 10px;
            margin-left: .5rem;
            background: url('./icons8-arrow-50-right.png') no-repeat left center;
            background-size: contain;
        
            content: '';
           
        }

    }
}