.appnavbar {
  background: #000;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  &-notification {
    color: #fff;
    div {
      display: inline-block;
      padding: 0.8rem;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
      position: relative;
      top: 0;
      left: 0;

      span {
        position: absolute;
        top: -0.5rem;
        right: -0.2rem;
        background: red;
        z-index: 10;
        border-radius: 999px;
        padding: 0.2rem;
      }
    }
  }
  &-brand {
    color: #fff;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 2rem;
  }
}
