.signup{
    position: absolute;
    right: 0.5rem;
    top: .2rem;
    border-radius: 3px;
    color: #0f7c90;
    background-color: transparent;
    // border: 1px solid #2896a9;
    padding: 4px 10px;
    cursor: pointer;
}
.login{
    margin-bottom: 20px;
    background-color:  rgba(255,255,255,0.8);
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.05);
    box-shadow: 0 1px 1px rgba(0,0,0,0.05)
}
.tc-btn{
        color: #fff;
        background-color: #0aa699;
        border-color: #2e6da4;

}
.login-input{
    font-family: 'Lato';
}