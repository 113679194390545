.advertisement__item__tbl{
   
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(4,1fr);
    grid-column-gap: .5rem;
  
    &-autocomplete{
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start:1;
        grid-row-end: 2;
     
    }

    &-title{
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
    }
    
    &-price{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
    }
    &-originalprice{
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;
    }
    &-online{
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 4;
        grid-row-end: 5;
        align-items: center;
    }
    &-btn{
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 5;
        grid-row-end: 6;
    }
    label{
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-align: right;
      }
}
