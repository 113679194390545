.table>tbody>tr>td{
    vertical-align: middle;
}
.table{
    font-size: 1.3rem;
    tr{
        font-family:Roboto, sans-serif;
        font-size:1.4rem;
        &:nth-child(even){
           background:  #f0f8ff ;
        }
        &:nth-child(odd){
            // background:  #fff;
        }
    }
    thead{
        tr{
            background:linear-gradient(to top, rgb(93, 129, 182) 0%, rgb(21, 70, 147) 100%) ;
            color: #fff;
            th{
                font-size: 1.6rem;
            }
        }
    }
}