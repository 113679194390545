.sidebar {
  position: absolute;
  height: 100%;
  width: 20rem;
  left: -20rem;
  z-index: 1;
  flex-direction: column;
  justify-content: flex-start;
  &__profile {
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 0.5rem;
    grid-template-areas:
      'img username'
      'img userstatus';
    border-bottom: 1px solid #e9ecf2;
    flex-basis: 7.5rem;
    justify-content: center;
    align-items: center;

    &-img {
      grid-area: img;
    }
    &-username {
      grid-area: username;
      align-self: flex-end;
      font-size: 1.6rem;
      font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: #444444;
    }
    &-user-status {
      grid-area: userstatus;
      align-self: flex-start;
      font-size: 1.2rem;
    }
    .indicator {
      width: 10px;
      height: 10px;
      display: inline-block;
      border-radius: 9999px;
      margin-right: 5px;
    }
  }
  &__menu {
    flex: 1;
    list-style: none;
    li {
      height: 5rem;
      padding: 0.5rem 1.5rem;
      box-sizing: border-box;
      color: #444444;
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      margin: 13px 10px;
      border-radius: 54px;
      background-color: #f6f8f8;
      cursor: pointer;
      .title{
          font-size: 1.6rem;
      }
      .icon{
        display: inline-block;
        width: 2rem;
      }
    }
  }
}
