.layout {
  display: flex;
  min-height: 100vh;
  justify-content: stretch;
  align-items: stretch;

  .sidebar {
    flex: 1;
    background: #fff;
  }

  .main {
    flex: 5;
    min-height: 100%;
  }
}

// blongs to tabs
.tabs {

  background: white;
  padding: 50px;
  padding-bottom: 80px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  min-width: 240px;
}

.tabs .content section h2,
.tabs ul li label {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 18px;
  color: #428BFF;
}

.tabs ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.tabs ul li {
  box-sizing: border-box;
  flex: 1;
  width: 25%;
  padding: 0 10px;
  text-align: center;
}

.tabs ul li label {
  transition: all 0.3s ease-in-out;
  color: #929daf;
  padding: 5px auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tabs ul li label br {
  display: none;
}

.tabs ul li label svg {
  fill: #929daf;
  height: 1.2em;
  vertical-align: bottom;
  margin-right: 0.2em;
  transition: all 0.2s ease-in-out;
}

.tabs ul li label:hover,
.tabs ul li label:focus,
.tabs ul li label:active {
  outline: 0;
  color: #bec5cf;
}

.tabs ul li label:hover svg,
.tabs ul li label:focus svg,
.tabs ul li label:active svg {
  fill: #bec5cf;
}

.tabs .slider {
  position: relative;
  width: 25%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}

.tabs .slider .indicator {
  position: relative;
  width: 50px;
  max-width: 100%;
  margin: 0 auto;
  height: 4px;
  background: #428BFF;
  border-radius: 1px;
}

.tabs .content {
  margin-top: 30px;
}

.tabs .content section {

  -webkit-animation-name: content;
  animation-name: content;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  line-height: 1.4;
}

.tabs .content section h2 {
  color: #428BFF;
  display: none;
}

.tabs .content section h2::after {
  content: "";
  position: relative;
  display: block;
  width: 30px;
  height: 3px;
  background: #428BFF;
  margin-top: 5px;
  left: 1px;
}


@-webkit-keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media (max-width: 1000px) {
  .tabs ul li label {
    white-space: initial;
  }

  .tabs ul li label br {
    display: initial;
  }

  .tabs ul li label svg {
    height: 1.5em;
  }
}

.tabs .slider {
  display: none;
}

.tabs .content {
  margin-top: 20px;
}

.tabs .content section h2 {
  display: block;
}

.top-bar {
  text-align: center;

  ul {
    height: 100%;
    display: flex;
    justify-content: center;

    li {
      color: #d0dff4;
      padding: 0 1rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      margin: 0 2rem;
      text-transform: uppercase;
      font-family: 'Lato';
      font-weight: 600;
      cursor: pointer;
      position: relative;
      top:0;
      left: 0;
      letter-spacing: 2px;
      &:hover .nested{
        display: flex;
      }
    }

    .nested {
      position: absolute;
      z-index: 1;
      top: 5rem;
      flex-direction: column;
      color: black;
      width: 20rem;
      align-items: start;
      background: rgb(235, 240, 247);
      justify-content: stretch;
      height: fit-content;
      box-shadow: #ccc 2px 2px 6px;
      display: none;
      &:hover{
        display: flex;  
      }
      li{
        padding: .6rem;
        color: #5e5c5c;
        letter-spacing: 1px;
        font-family: Roboto , sans-serif;
        font-size: 1.2rem;
       
      }
    }
  }
}