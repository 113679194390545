.signup__modal{
     position: fixed;
     top: 0;
     left: 0;
     z-index: 10;
     width: 100%;
     height: 100vh;
     background: #fff;

     h1{
         text-align: center;
     }
     &-close{
        position:absolute;
        right:.5rem;
        top:1rem;
        cursor: pointer;
     }
}