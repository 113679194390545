body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: fontawesome;
  src: url(./css/fonts/fontawesome-webfont.ttf);
}

@font-face {
  font-family: MiriamLibre-Regular;
  src: url(./css/fonts/MiriamLibre-Regular.ttf);
}

@font-face {
  font-family: LatoBlack;
  src: url(./css/fonts/Lato-Black.ttf);
}
