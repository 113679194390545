.advertisement__form{
    display: grid;
   
    grid-template-areas: 'title title'
                         'subtitle subtitle'
                         'users users'
                         'lang lang'
                         'rabbi rabbi'
                         'date date'
                          'btn btn';
    &-title{
        grid-area: title;
        width: 100%;
        .form-control{
            height: 37px;
        }
    }
   
    &-subtitle{
        grid-area: subtitle;
        width: 100%;
        .form-control{
            height: 37px;
        }
    }
   
    &-date{
        grid-area: date;
        
    }
    
    &-end_date{
        grid-area: end_date;
    }
    &-users{
        grid-area: users;
    }
    &-lang{
        grid-area: lang;
        .form-control{
            height: 37px;
        }
    }
    &-rabbi{
        grid-area: rabbi;
    }
    &-datepicker{
        width: 100%;
        .react-datetime-picker__wrapper{
            border-color: #ccc;
        }
    }
    &-start_date,&-end_date{
        input {
            display: block;
            width: 100%;
            height: 34px;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857143;
            color: #555;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            border-radius: 4px;
          
           
           
        }
    }

    &-btn{
        grid-area: btn;
    }
    label{
        font-family: Arial, Helvetica, sans-serif;
    }
}

